import React, { useEffect, useState } from "react";
import { Row, Col, Alert, Modal, ModalBody, ModalHeader } from "reactstrap";
import { isMobile } from "react-device-detect";
import {
  MdCheck,
  MdCheckBox,
  MdCheckCircle,
  MdClose,
  MdCloseFullscreen,
  MdEmail,
  MdInfo,
  MdInfoOutline,
  MdRemoveCircle,
  MdRocket,
  MdStopCircle,
} from "react-icons/md";
import moment from "moment";
import "moment-timezone";
import { get } from "lodash";
import { useLocation, Link } from "react-router-dom";
import { AvForm, AvField } from "availity-reactstrap-validation";
import queryString from "query-string";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import Loader from "react-loader-spinner";
import {
  CommonLoader,
  LoginToken,
  TokenAppenditure,
  Breadcrumbs,
} from "../Utility/common";
import { Capacitor } from "@capacitor/core";
import "./style.scss";
import ActionButtons from "./ActionButtons";
import Alerts from "./Alerts";
import ContactModal from "../Contact/ContactModal";
import ProgressSteps from "./ProgressSteps";

function numToWords(n) {
  const words = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  return words[n];
}

function isValidHttpUrl(string) {
  let url;

  try {
    url = new URL(string);
  } catch (_) {
    return false;
  }
  return url.protocol === "http:" || url.protocol === "https:";
}

function returnPlatformDomain(platform) {
  if (platform.includes("reels")) {
    return "instagram";
  } else if (platform.includes("shorts")) {
    return "youtube";
  }
  return platform;
}

const CampaignDetail = ({ postID, uid, isOpen }) => {
  const location = useLocation();
  const campaignType = location.pathname.slice(10);
  const [url, setURL] = useState(null);
  const [results, setResults] = useState(null);
  const [isNotifyMode, setIsNotifyMode] = useState(false);
  const [steps, setSteps] = useState();
  const [activeStep, setActiveStep] = useState();
  const [feedbackModal, setFeedbackModal] = useState(false);
  const toggleFeebackModal = () => {
    setFeedbackModal(!feedbackModal);
  };
  let params = postID
    ? { PostID: postID, UID: uid }
    : queryString.parse(location.search);
  const [modalOpen, setModalOpen] = useState(false);
  const toggle = () => {
    setModalOpen(!modalOpen);
  };
  const loginToken = LoginToken(); // => 'value'
  const [isUploading, setIsUploading] = useState(false);
  const [error, setError] = useState();
  const localPostID = postID ? postID : params.PostID;
  const localUID = uid ? uid : params.UID;
  const getSummaryResults = () => {
    fetch(
      `https://api.genni.com/campaignResultsSummary?x-api-key=${loginToken}&postId=${localPostID}&uid=${localUID}`,
      { method: "GET" }
    )
      .then((res) => {
        if (res.ok) {
          return res.json();
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        if (!data.error) {
          setResults(data);
        }
      });
  };

  const submitPostNotif = () => {
    fetch(`https://portalapi.genni.com/api/v1/creatorapi/campaignNotify`, {
      method: "POST",
      headers: { "Content-Type": "application/json", "x-api-key": loginToken },
      body: JSON.stringify({
        postId: parseInt(localPostID),
        uid: localUID,
        postUrl: url,
      }),
    })
      .then(async (res) => {
        if (res.ok) {
          return res.json();
        } else {
          setError("There has been an error");
        }
        throw res;
        //const jsonRes = JSON.parse(res);
      })
      .then((data) => {
        setData();
        setActiveStep();
        setSteps();
        // window.location.href = `/?LoginToken=${params.LoginToken}`;
      });
  };

  const calcDateValue = (d) => {
    const t = new Date("January 1 1970 00:00:00-0:00");
    t.setSeconds(t.getSeconds() + parseFloat(d));
    const momentT = moment(t);
    return momentT.tz(moment.tz.guess()).format("M/D/YYYY") === "Invalid date"
      ? ""
      : momentT.tz(moment.tz.guess()).format("MMM D hh:mmA z");
  };

  const handleURLChange = (e) => {
    setURL(e.target.value);
  };

  const [data, setData] = useState();
  const [postURLErrorMessage, setPostURLErrorMessage] = useState();
  const [payPalEmail, setPayPalEmail] = useState();
  useEffect(() => {
    if (!data) {
      fetch(`https://portalapi.genni.com/api/v1/creatorapi/campaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          "x-api-key": loginToken,
        },
        body: JSON.stringify({ postId: parseInt(localPostID), uid: localUID }),
      })
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          getSummaryResults();
          setData(data);
          setSteps(data.results.Step.Available);
          setActiveStep(data.results.Step.Current);
        });
    }

    if (!payPalEmail) {
      fetch(
        `https://portalapi.genni.com/api/v1/creatorapi/vendor?x-api-key=${loginToken}`,
        {
          method: "GET",
        }
      )
        .then((res) => {
          if (res.ok) {
            return res.json();
          }
          throw res;
          //const jsonRes = JSON.parse(res);
        })
        .then((data) => {
          setPayPalEmail(
            data.results.data.ContactInformation.EmailAddress_Payment
              ? data.results.data.ContactInformation.EmailAddress_Payment
              : ""
          );
        });
    }
  }, [data]);

  const urlValidator = () => {
    if (data && url && isValidHttpUrl(url)) {
      if (
        url.toLowerCase().includes(data.results.Platform.toLowerCase()) ||
        (data.results.Platform.toLowerCase().includes("reels") &&
          url.toLowerCase().includes("instagram")) ||
        (data.results.Platform.toLowerCase().includes("shorts") &&
          url.toLowerCase().includes("youtube"))
      ) {
        if (postURLErrorMessage) {
          setPostURLErrorMessage(``);
        }
        return true;
      } else {
        if (!postURLErrorMessage) {
          setPostURLErrorMessage(
            `Must be a valid ${data.results.Platform.toLowerCase()} link`
          );
        }
        return false;
      }
    } else {
      return false;
    }
  };

  const returnURLForm = () => {
    return (
      <>
        {data && data.results.FeedbackNotes && (
          <>
            <div
              className="border"
              style={{
                borderRadius: "8px",
                padding: "20px",
                marginTop: "1rem",
                marginBottom: "1rem",
                backgroundColor: "#fff",
              }}
            >
              <div
                className="mb-2"
                style={{ margin: "auto", textAlign: "center" }}
              >
                <MdCheckCircle size={50} color="#32a852" />
              </div>
              <h3 className="madeGentle" style={{ textAlign: "center" }}>
                Draft Approved
              </h3>
              <h4 style={{ marginTop: "20px", fontSize: 14 }}>Feedback:</h4>
              <div
                style={{
                  marginTop: "0px",
                  color: "#555",
                  textAlign: "left",
                  backgroundColor: "#f9f9f9",
                  padding: "10px",
                  borderRadius: "4px",
                  border: "1px solid #ddd",
                }}
                dangerouslySetInnerHTML={{ __html: data.results.FeedbackNotes }}
              />
            </div>
          </>
        )}
        <div className="bg-white p-3 mb-3 card">
          {error && <Alert color="danger">{error}</Alert>}

          <div className="">
            {postURLErrorMessage && (
              <Alert color="danger">{postURLErrorMessage}</Alert>
            )}
            <AvForm>
              <AvField
                name="url"
                label="Enter Post URL"
                className="inputField"
                placeholder={`Ex. https://${
                  data &&
                  returnPlatformDomain(data.results.Platform.toLowerCase())
                }.com`}
                type="text"
                errorMessage="Invalid Post URL"
                validate={{
                  required: { value: true },
                }}
                onChange={handleURLChange}
                value={url}
              />
            </AvForm>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <div
                className={`btn btn-pink gradient-pink full-width mb-3 ${
                  urlValidator() ? "" : "disabled"
                }`}
                onClick={() => {
                  setIsUploading(true);
                  if (urlValidator()) {
                    submitPostNotif();
                  }
                }}
                style={{ width: "100%", marginRight: "8px" }}
              >
                {isUploading ? (
                  <Loader type="Bars" color="#fff" height={20} width={40} />
                ) : (
                  "Upload Post URL"
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  };

  const returnActiveStepInt = () => {
    let c = 0;
    if (steps) {
      steps.map((e, i) => {
        if (e === activeStep) {
          c = i;
        }
      });
    }
    return c;
  };
  const campaignDescription = get(
    data,
    ["data", "Row_CampaignDetails", 3, "Row_CampaignDetails_Fields"],
    []
  );
  const campaignTitle = campaignDescription.map((e) => {
    if (e.FieldName === "Campaign Title") {
      return e.FieldValue;
    }
  });

  const [activeAccordion, setActiveAccordion] = useState(0);
  return (
    <React.Fragment>
      <style>{`${isOpen && `html{overflow:hidden}`}`}</style>
      {/* <div style={{width:'100%', height:90, background:'#162638', marginTop:'-17px'}}></div> */}

      <div
        className={`page-content ${
          !Capacitor.isNativePlatform() && !isMobile && "pt-2"
        }`}
      >
        <div className="container">
          {/* <div
            className="p-3 position-relative bg-white overflow-hidden rounded border title-sec"
            style={{ zIndex: 1, marginTop:-90}}
          >
              <div className="">
                <button
                  className="play-box"

                  style={{
                    height:70,
                    width:70,
                    backgroundImage:
                      'url("https://preffy-webbcb877c98a0749c083b216aad631f2df162954-staging.s3.amazonaws.com/images/run run.jpeg")'
                  }}
                >
                  <div className="overlay">
                    <div className="play-button">
                      <svg
                        viewBox="0 0 26 26"
                        xmlns="http://www.w3.org/2000/svg"
                        style={{ width: "100%", height: "100%", color: "white" }}
                      >
                        <title>Play</title>
                        <path
                          d="M7.712 22.04a.732.732 0 0 1-.806.007.767.767 0 0 1-.406-.703V4.656c0-.31.135-.544.406-.703.271-.16.54-.157.806.006l14.458 8.332c.266.163.4.4.4.709 0 .31-.134.546-.4.71L7.712 22.04z"
                          fill="currentColor"
                          fillRule="evenodd"
                        />
                      </svg>
                    </div>
                  </div>
                </button>
              </div>
              <div className="title-text-sec">
                <h3 className="title mb-0 madeGentle" style={{ lineHeight: "1.2" }}>
                  Make A Life Not A Living
                </h3>
                <a href="">
                  <small className="text-muted h6 mr-2">Island Records</small>
                </a>
              </div>
          </div> */}
          {Breadcrumbs(
            campaignTitle ? campaignTitle : "",
            "Campaigns",
            `/campaigns${TokenAppenditure()}`
          )}
          <Row>
            <Col className="col-12">
              <div
                style={{
                  marginTop: `${
                    Capacitor.isNativePlatform() || isMobile ? "0px" : "1rem"
                  }`,
                }}
              >
                <Alerts
                  data={data}
                  isNotifyMode={isNotifyMode}
                  params={params}
                  postID={params.PostID}
                  uid={params.UID}
                />
              </div>
              {steps && <ProgressSteps steps={steps} activeStep={activeStep} />}

              <ActionButtons
                payPalEmail={payPalEmail}
                setData={setData}
                setSteps={setSteps}
                setActiveStep={setActiveStep}
                params={params}
                data={data}
                results={results}
                urlForm={returnURLForm()}
                platformDomain={
                  data &&
                  returnPlatformDomain(data.results.Platform.toLowerCase())
                }
              />
              {data && data.results?.IsBoostCodeRequired && (
                <div className="card p-2 border shadow-none cpCard">
                  <div className="cpStuff">
                    <MdRocket color="#f853bd" size="28" className="mr-3" />
                    <div className="">
                      <p className="text-muted text-md mb-0 py-2">
                        The post's boost code must be added once the campaign is
                        complete.
                      </p>
                    </div>
                  </div>
                  <a
                    href="https://blog.onerpm.com/latest-en/tiktok-tips-spark-ads/"
                    target="_blank"
                  >
                    <button className="btn btn-light btn-sm">
                      <MdInfoOutline
                        className="mr-1"
                        style={{ marginTop: "-3px" }}
                      />
                      Learn more
                    </button>
                  </a>
                </div>
              )}

              <div
                className="accordion card border-none shadow-none"
                id="accordionExample"
                style={{ width: "100%" }}
              >
                {data &&
                  data.results.Row_CampaignDetails.map((e, i) => {
                    if (e.Row_CampaignDetails_Fields.length > 0) {
                      return (
                        <div className="accordion-item ">
                          <h2
                            className="accordion-header  text-pink "
                            id={`heading${numToWords(i + 1)}`}
                            onClick={() => {
                              setActiveAccordion(i);
                            }}
                          >
                            <button
                              className={`accordion-button shadow-none ${
                                i === activeAccordion && "border-bottom"
                              } ${i !== activeAccordion && "collapsed"}`}
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={`#collapse${numToWords(i + 1)}`}
                              aria-expanded={i === 0 ? "true" : "false"}
                              aria-controls={`collapse${numToWords(i + 1)}`}
                            >
                              {e.Row_CampaignDetails_Header}
                            </button>
                          </h2>
                          <div
                            id={`collapse${numToWords(i + 1)}`}
                            className={`accordion-collapse collapse ${
                              i === activeAccordion && "show"
                            }`}
                            aria-labelledby={`heading${numToWords(i + 1)}`}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <div className="campaign-details">
                                {e.Row_CampaignDetails_Fields.map(
                                  (a, count) => {
                                    if (a.FieldValue) {
                                      return (
                                        <>
                                          <div className="campaign-detail">
                                            <p className="flex-col d-flex">
                                              {a.FieldValue && (
                                                <>
                                                  <span className="fw-semi">
                                                    {a.FieldName}
                                                  </span>{" "}
                                                  {isValidHttpUrl(
                                                    a.FieldValue
                                                  ) ? (
                                                    <a
                                                      href={a.FieldValue}
                                                      className="text-pink"
                                                      target="_blank"
                                                    >
                                                      {a.FieldValue}
                                                    </a>
                                                  ) : (
                                                    <span
                                                      dangerouslySetInnerHTML={{
                                                        __html:
                                                          a.FieldName.includes(
                                                            "Date"
                                                          ) &&
                                                          a.FieldValueDateTime
                                                            ? a.FieldValueDateTime
                                                            : a.FieldValue,
                                                      }}
                                                    ></span>
                                                  )}
                                                </>
                                              )}
                                            </p>
                                          </div>
                                        </>
                                      );
                                    }
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      );
                    }
                  })}
              </div>
              {data ? (
                <div className="card p-4 border shadow-none cpCard">
                  <div className="cpStuff">
                    <img
                      src={data.results.Branding.SecondaryLogoURL}
                      style={{ borderRadius: "100%", objectFit: "cover" }}
                    />
                    <div className="offeredByWrapper">
                      <p className="text-muted text-sm mb-0">Offered by</p>
                      <h6 className="mt-1">
                        {data.results.Branding.ConnectedPartnerName}
                      </h6>
                    </div>
                  </div>

                  <button className="btn btn-light" onClick={toggle}>
                    <MdEmail className="mr-1" style={{ marginTop: "-3px" }} />
                    Contact
                  </button>
                </div>
              ) : (
                <CommonLoader />
              )}
            </Col>
          </Row>
        </div>
      </div>

      <ContactModal
        isOpen={modalOpen}
        toggle={toggle}
        cpName={data && data.results.Branding.ConnectedPartnerName}
      />
    </React.Fragment>
  );
};

export default CampaignDetail;
